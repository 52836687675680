import React from 'react';
import { Link } from 'gatsby';

import Layout from '../components/Layout';
import { ReactComponent as Logo } from '../images/logo.svg';
import SEO from '../components/seo/SEO';

const NotFoundPage = () => (
  <Layout empty>
    <SEO title="404: Not found" />
    <div className="o-wrapper">
      <div className="u-padding-large u-align-center">
        <div className="u-padding-bottom">
          <Link to="/"><Logo style={{ width: 150 }} /></Link>
        </div>
        <h1 className="o-largeTitle">404: NOT FOUND</h1>
        <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
        <Link to="/">Go to Home</Link>
      </div>
    </div>
  </Layout>
);

export default NotFoundPage;
